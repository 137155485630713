<template>
  <v-dialog v-model="show" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="ml-2"> mdi-cog </v-icon>
    </template>
    <v-card height="70%">
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Configure Kiosk </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card flat height="500" class="overflow-y-auto">
        <v-card-title class="subtitle-1">CMS Config</v-card-title>
        <v-card-text>
          <v-form ref="cmsConfigKioskForm" lazy-validation class="px-6">
            <v-text-field
              v-model="config.cmsLink"
              label="CMS Address URL (https://...)"
              :rules="rules.cmsAddressRules"
              required
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title class="subtitle-1">Telepresence Config</v-card-title>
        <v-card-text>
          <v-form ref="cmsConfigKioskForm" lazy-validation class="px-6">
            <v-text-field
              v-model="config.videoLink"
              label="Telepresence URL (https://...)"
              :rules="rules.videoLinkRules"
              required
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title class="subtitle-1">Avatar Config</v-card-title>
        <v-card-text>
          <v-form ref="avatarConfigKioskForm" lazy-validation class="px-6">
            <v-text-field
              v-model="config.sitepalId"
              label="Sitpal ID"
              :rules="rules.generalFieldRule"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="config.avatarId"
              label="Avatar ID"
              :rules="rules.generalFieldRule"
              required
            >
            </v-text-field>
            <!-- <v-sheet class="mx-auto" flat max-width="1000">
              Select Avatar
              <v-slide-group
                v-model="model"
                class="pa-4"
                active-class=""
                show-arrows
              >
                <v-slide-item
                  v-for="n in 6"
                  :key="n"
                  v-slot="{ active, toggle }"
                >
                  <v-card class="ma-4" height="100" width="100" @click="toggle">
                     n 
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet> -->
            <v-text-field
              label="Avatar Name"
              required
              v-model="config.avatarName"
              :rules="rules.generalFieldRule"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title class="subtitle-1">Chatbot Config</v-card-title>
        <v-card-text>
          <v-form ref="chatbotConfigKioskForm" lazy-validation class="px-6">
            <v-text-field
              v-model="config.host"
              label="Host Address (https://...)"
              :rules="rules.hostAddressRules"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="config.route"
              label="Route (/knowledgebases/..../generateAnswer)"
              :rules="rules.routeRules"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="config.endpointKey"
              label="Endpoint Key"
              :rules="rules.endpointRules"
              @keyup.enter="saveKioskConfig"
              required
            >
            </v-text-field>
          </v-form>
        </v-card-text>
      </v-card>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="show = !show"> Cancel </v-btn>
        <v-btn
          color="primary"
          text
          @keyup.enter="saveKioskConfig"
          @click="saveKioskConfig"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "kioskConfig",
  props: {
    kioskObject: Object,
  },
  data() {
    return {
      show: false,
      loading: false,
      config: {
        videoLink: "",
        cmsLink: "",
        host: "",
        endpointKey: "",
        route: "",
        kId: null,
        sitepalId: null,
        avatarId: null,
        avatarName: null,
      },
      rules: {
        cmsAddressRules: [(v) => !!v || "CMS address is required"],
        hostAddressRules: [(v) => !!v || "Host address is required"],
        routeRules: [(v) => !!v || "Route is required"],
        endpointRules: [(v) => !!v || "Endpoint key is required"],
        videoLinkRule: [(v) => !!v || "Telepresence URL is required"],
        generalFieldRule: [(v) => !!v || "Field cannot be left empty"],
      },
    };
  },
  methods: {
    saveKioskConfig() {
      if (
        this.$refs.cmsConfigKioskForm.validate() &&
        this.$refs.chatbotConfigKioskForm.validate() &&
        this.$refs.avatarConfigKioskForm
      ) {
        this.loading = true;
        this.$store.dispatch("saveKioskConfig", this.config).then(
          (response) => {
            this.loading = false;
            this.show = !this.show;
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "success",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },
  },
  mounted() {
    this.config = this.kioskObject.data();
    this.config.kId = this.kioskObject.id;
  },
};
</script>