<template>
  <v-dialog v-model="show" max-width="40%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary">
        <v-icon left>
          mdi-plus
        </v-icon>
        Add New Kiosk
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        Add New Kiosk
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
      </v-progress-linear>
      <v-card-text>
        <v-form ref="addNewKioskForm" lazy-validation class="px-6">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="kiosk.name" label="Kiosk's Name" :rules="rules.nameRules" required>
              </v-text-field>
              <v-text-field v-model="kiosk.location" label="Location" :rules="rules.locationRule" required>
              </v-text-field>
              <v-text-field v-model="kiosk.email" label="E-mail" :rules="rules.emailRules" required>
              </v-text-field>
              <v-textarea outlined label="Description" @keyup.enter="addNewKiosk" v-model="kiosk.description">
              </v-textarea>
            </v-col>
            <v-col cols="12" align="end">
              <v-btn color="error" dark large @click="show = false" class="mx-2" outlined width="150">
                <v-icon left>mdi-cancel</v-icon>
                Cancel
              </v-btn>

              <v-btn color="primary" dark large @keyup.enter="addNewKiosk" @click="addNewKiosk" width="150">
                <v-icon left>mdi-plus</v-icon>
                Add Kiosk
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text align="center" class="pt-2">
        <span class="black--text text-subtitle-1">Invitation link sent to the kiosk's email will be use to set the password and activate the kiosk.</span>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: 'addNewKiosk',
  data() {
    return {
      show: null,
      loading: false,
      kiosk: {
        name: '',
        email: '',
        description: '',
        location: '',
        fdn: window.location.hostname,
      },
      rules: {
        nameRules: [
          v => !!v || 'Kiosk name is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        locationRule: [
          v => !!v || 'Location is required',
        ]
      }
    }
  },
  methods: {
    addNewKiosk() {
      if (this.$refs.addNewKioskForm.validate()) {
        this.loading = true;
        this.$store.dispatch('inviteNewKiosk', this.kiosk)
          .then(response => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'green'
            })
          }, error => {
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    }

  },
  computed: {

  },
  mounted() {

  }
}
</script>