<template>
  <div>
    <v-data-table
      v-if="viewType === 'list'"
      :loading="loading"
      :headers="registeredKiosksHeaders"
      :items="allRegisteredKiosks"
    >
      <template v-slot:item.name="{ item }">
        {{ item.data().name }}
      </template>
      <template v-slot:item.location="{ item }">
        {{ item.data().location }}
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.data().email }}
      </template>
      <template v-slot:item.last_changed="{ item }">
        {{ item.data().last_changed | formatDate }}
      </template>
      <template v-slot:item.createdOn="{ item }">
        {{ item.data().createdOn | formatDate }}
      </template>
      <template v-slot:item.onlineStatus="{ item }">
        <v-chip dark small :color="getColor(item.data().onlineStatus)">
          {{ item.data().onlineStatus }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="userProfile.data().userRole.toLowerCase() === 'super admin'">
          <editAndDeleteKiosk  kioskType="registered" :kioskIndex="item">
        </editAndDeleteKiosk>
        <kioskConfig :kioskObject="item"> </kioskConfig>
        </div>
        <div v-else>
          NA
        </div>
        
      </template>
    </v-data-table>

    <v-row v-else class="fill-height py-2">
      <v-col
        v-for="(kiosk, i) in allRegisteredKiosks"
        :key="i"
        md="2"
        sm="2"
        lg="3"
        xl="2"
        cols="12"
      >
        <v-card
          class="cardBorderOffline"
          :class="getCardClass(kiosk.data().onlineStatus)"
          flat
        >
          <v-card-actions class="subtitle-1 mb-n10">
            {{ kiosk.data().name }}
          </v-card-actions>
          <br />
          <v-card-actions class="caption grey--text text--darken-1">
            {{ kiosk.data().location | trimLength }}
          </v-card-actions>
          <v-card-actions>
            <span class="caption grey--text text--darken-1">
               {{ kiosk.data().last_changed | formatDate }}
            </span>
            <v-spacer></v-spacer>
            <div>
              <editAndDeleteKiosk
                kioskType="registered"
                :kioskIndex="kiosk"
              ></editAndDeleteKiosk>
              <kioskConfig v-if="userProfile.data().userRole.toLowerCase() === 'super admin'" :kioskObject="kiosk"></kioskConfig>
              <kioskInformation :kioskObject="kiosk"></kioskInformation>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import editAndDeleteKiosk from "@/components/dialogBox/kiosks/editAndDeleteKiosk.vue";
import kioskInformation from "@/components/dialogBox/kiosks/kioskInformation.vue";
import kioskConfig from "@/components/dialogBox/kiosks/kioskConfig.vue";

export default {
  name: "registeredKioskList",
  components: {
    editAndDeleteKiosk,
    kioskConfig,
    kioskInformation,
  },
  props: {
    viewType: String,
  },
  data() {
    return {
      loading: false,
      registeredKiosksHeaders: [
        { text: "Name", align: "start", value: "name" },
        { text: "Location", value: "location" },
        { text: "Email", value: "email" },
        { text: "Status", value: "onlineStatus" },
        { text: "Last Online", value: "last_changed" },
        { text: "Joined", value: "createdOn" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["allRegisteredKiosks", 'userProfile']),
  },
  methods: {
    getColor(status) {
      if (status === "Unavailable") return "error";
      if (status === "Busy") return "amber";
      if (status === "Available") return "success";
    },
    getCardClass(status) {
      if (status === "Unavailable") return "cardBorderOffline";
      if (status === "Busy") return "cardBorderOnline";
      if (status === "Available") return "cardBorderBusy";
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val === null) return val;
      if (val.length <= 40) {
        return val;
      }
      return `${val.substring(0, 35)}...`;
    },
  },
};
</script>

<style>
.cardBorderOffline {
  border-left: 6px solid #ff5252 !important;
  border-top: 0.5px solid #bdbdbd !important;
  border-bottom: 0.5px solid #bdbdbd !important;
  border-right: 0.5px solid #bdbdbd !important;
}
.cardBorderOnline {
  border-left: 6px solid #4caf50 !important;
  border-top: 0.5px solid #bdbdbd !important;
  border-bottom: 0.5px solid #bdbdbd !important;
  border-right: 0.5px solid #bdbdbd !important;
}
.cardBorderBusy {
  border-left: 6px solid #4caf50 !important;
  border-top: 0.5px solid #bdbdbd !important;
  border-bottom: 0.5px solid #bdbdbd !important;
  border-right: 0.5px solid #bdbdbd !important;
}
</style>